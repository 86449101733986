import React, { useEffect, useState, useCallback, version } from "react";
import "./addProduct.scss";
import Button from "../../../components/button/button";
import importIcon from "../../../assets/images/home.png";
import StoreListing from "../../../components/storeEmpty/storeListingEmpty";
import TabBar from "./components/tabs/tabs";
import SpinnerLoader from "../../../components/loader/spinnerLoader";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListViewOption from "../../../assets/images/listViewIcon.svg";
import GridIcon from "../../../assets/images/gridIcon.svg";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { deleteMethodWithToken, getMethod, getMethodWithoutBaseUrl, postMethodWithToken, postMethodWithTokenProgress, postMethodWithTokenProgressCSV, putMethodWithToken } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { getStoreId, getProductId, setProductId, setProductEditableFlag, setInventoryEditedFlag, setShippingEditedFlag, setAddonsEditedFlag } from "../../../utils/common";
import Switch from "react-switch";
import ThumbNailView from "./components/thumbNailView/thumbNailView";
import NoImage from "../../../assets/images/storelisting.png";
import { useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from '@mui/material/Pagination';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CSVFile from "../../../assets/csv/Product-sample.xlsx"
import OnHoldModal from "../../../components/OnHoldModal/onHoldModal";
import emptyStore from "../../../assets/images/storelisting.png"

let timeOutId;
window.$productDetails = [];
window.$inventoryDetails = [];
window.$spec = "";
window.$desc = "";
window.$ColorToggle = false;

function AddProduct(props) {
    window.$addProduct = false;
    const itemsPerPage = 20;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const history = useHistory();
    const [addProduct, setAddProduct] = useState(window.$addProduct);
    const [listviewData, setListviewData] = useState([]);
    const [cardViewList, setCardViewList] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [indexIdToDelete, setIndexIdToDelete] = useState("");
    const [noMatch, setNoMatch] = useState(false);
    const [expandIndex, setExpandIndex] = useState("");
    const [hide, setHide] = useState(true);
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [onBoarding, setOnBoarding] = useState(false);
    const [uploadModal, setUploadModal] = useState(false)
    const [formValue, setFormValue] = useState({
        file: "",
    });
    const [filePath, setFilePath] = useState("upload XLSX")
    const [cloneConfirmation, setCloneConfirmation] = useState(false)
    const [cloneId, setCloneId] = useState("")
    const [cloneUpc, setCloneUpc] = useState("")
    const [cloneSku, setCloneSku] = useState("")
    const [structure, setStructure] = useState("")
    const [childrenClone_upc, setChildrenColne_upc] = useState([]);
    const [childrenClone_sku, setChildrenColne_sku] = useState([]);
    const [childrenClone_id, setChildrenClone_id] = useState([]);
    const [varaintData, setVaraintData] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [modalMe, setModalMe] = useState(false)

    const productPage = () => {
        getCurrentStatus()
        setAddProduct(true);
        setProductId("");
        setProductEditableFlag('added');
        setInventoryEditedFlag('added');
        setAddonsEditedFlag('added');
        setShippingEditedFlag('added');
        window.$addProduct = true;
        window.$spec = "";
        window.$desc = "";
    };

    const allStyles = {
        content: {
            top: screenSize.width <= 1400 ? "20%" : "13%",
            left: "73.5%",
            // transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "10px",
            width: screenSize.width <= 800 ? "20vw" : screenSize.width <= 1500 ? "23vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "20vh",
            minHeight: '230px',
        },
    };

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const cloneStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "50vw" : screenSize.width <= 1100 ? "30vw" : "28vw",
            height: screenSize.width <= 800 ? "20vh" : "27vh",
        },
    };

    const useStyles = makeStyles((theme) => ({
        draftToolTip: {
            backgroundColor: "rgb(255, 127, 0)",
        },
        draftToolTipArrow: {
            color: "rgb(255, 127, 0)",
        },
        activeToolTip: {
            backgroundColor: "rgb(0, 180, 0)",
        },
        activeToolTipArrow: {
            color: "rgb(0, 180, 0)",
        },
        deactiveToolTip: {
            backgroundColor: "rgb(255, 0, 0)",
        },
        deactiveToolTipArrow: {
            color: "rgb(255, 0, 0)",
        },
    }));

    const classes = useStyles();

    const cardView = () => {
        setCardViewList(true);
        getCurrentStatus()
    };

    const listViewData = () => {
        setCardViewList(false);
        getCurrentStatus()
    };

    const updateDataOfListing = async (url) => {
        setLoader(true);
        await getMethodWithoutBaseUrl(url).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListviewData(response?.data?.results);
                setLoader(false);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setLoader(false);
    };

    useEffect(() => {
        getCurrentStatus();
        listedItem();
        onBoardingStatus();
    }, []);

    const onBoardingStatus = async () => {
        await getMethod(urls.verifyOnboardStatus).then((response) => {
            if (response.status === 200) {
                setOnBoarding(response.data.status);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    window.$progressStatus = "20%";

    const getCurrentStatus = async () => {
        await getMethod(urls.getUserDetailsMe).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
                window.$step = response.data.signup_step;
                if (response.data.signup_step <= 0) {
                    localStorage.setItem("progressStatus", "0");
                }
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const debounce = (func, delay) => {
        return (...args) => {
            if (timeOutId) clearTimeout(timeOutId);
            timeOutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const listedItem = async () => {
        setLoading(true);
        let storeId = getStoreId();
        await getMethod(urls.listview + storeId).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListviewData(response.data.results);
                setLoading(false);
            }
        })
        // .catch((error) => {
        //     console.log("error", error);
        // });
        // setLoading(false);
    };

    const listedItemWithoutLoader = async () => {
        let storeId = getStoreId();
        await getMethod(urls.listview + storeId).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListviewData(response.data.results);
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const handlePageChange = async (event, value) => {
        setLoading(true);
        getCurrentStatus()
        setPage(value)
        let storeId = getStoreId();
        await getMethod(urls.listview + storeId + "&page=" + value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListviewData(response?.data?.results);
                setLoading(false);
            }
        });

        setLoading(false);
    }

    const goToDetails = async (productClicked, index) => {
        setProductId(productClicked.id);
        setAddProduct(true);
        setProductEditableFlag('editable')
        window.$productDetails = productClicked;
    };

    const goToDetailsProduct = async (productClicked, innerProduct, child) => {
        setProductId(productClicked.id);
        setAddProduct(true);
        setProductEditableFlag('editable')
        window.$productDetails = productClicked;
    };

    const postUPCListCheck = async (data) => {
        let body = {
            "check_upc": data
        };

        await postMethodWithToken(urls.postUPCListCheck, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                // toast.success(response.data.response)
            } else if (response.status === 400) {
                toast.error(response?.data?.response)
            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const postSKUListCheck = async (data) => {
        let body = {
            "check_sku": data
        };

        await postMethodWithToken(urls.postSKUListCheck, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                // toast.success(response.data.response)
            } else if (response.status === 400) {
                toast.error(response?.data?.response)
            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const handleInputUPCChange = (event) => {
        const { name, value } = event.target
        setCloneUpc(value)
        if (name === "UPC") {
            postUPCListCheck(value)
        }
    }

    const handleInputSKUChange = (event) => {
        const { name, value } = event.target
        setCloneSku(value)
        if (name === "SKU") {
            postSKUListCheck(value)
        }

    }

    const cloneProduct = async (event, id) => {
        event.preventDefault();
        setButtonDisabled(true);
        let body = {
            "product_id": id,
            "structure": structure,
            "clone_upc": cloneUpc,
            "clone_sku": cloneSku
        }
        await postMethodWithTokenProgress(urls.productClone, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.response);
                setCloneConfirmation(false);
                getCurrentStatus()
                listedItem();
            } else {
                toast.error(response?.data?.response);
            }
        })
        setButtonDisabled(false);

    }

    const cloneProductParent = async (event, id) => {
        event.preventDefault();
        setButtonDisabled(true);
        var objMap = new Map(Object.entries(childrenClone_sku));
        let array = [];
        objMap.forEach((item, key) => {
            array.push(item[0]);
        });

        for (let i = 0; i < varaintData.length; i++) {
            varaintData[i].clone_sku = array[i]?.clone_sku
        }

        let body = {
            "product_id": id,
            "structure": structure,
            "varient_data": varaintData
        }

        await postMethodWithTokenProgress(urls.productClone, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.response);
                setCloneConfirmation(false);
                setVaraintData([]);
                setChildrenColne_upc([]);
                setChildrenColne_sku([]);
                getCurrentStatus()
                listedItem();
            } else {
                toast.error(response?.data?.response);
            }
        })
        setButtonDisabled(false);

    }

    const deleteProduct = async (id) => {
        // setConfirmation(false);
        // setLoading(true);
        let storeId = getStoreId();
        await deleteMethodWithToken(
            urls.aboutStore + storeId + "/product/" + id + "/productdelete/").then((response) => {
                console.log(response)
                if (response.status === 200 || response.status === 201) {
                    toast.success(response?.data?.response);
                    listedItem();
                    getCurrentStatus()
                    setConfirmation(false);
                    setLoading(true)
                } else {
                    toast.error(response?.data?.response)
                }
            });
        // setLoading(false);
    };

    const myFun = (val) => {
        setHide(val);
    };

    const productStatusUpdate = async (status, id) => {
        let body = {
            is_status: status
        }

        await putMethodWithToken(urls.putProductStatus + id + "/", body).then((response) => {
            if (response.status === 201 || response.status === 200) {
                toast.success(response?.data?.response)
                listedItem();
            } else {
                toast.error(response?.data?.response);
            }
        });
    }

    const showProductList = () => {
        if ((loading && listviewData.length > 0) || loader) {
            return <SpinnerLoader />;
        } else if (!addProduct && listviewData.length > 0 && !cardViewList) {
            return (
                <>
                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>

                    <div className="col-12">
                        <div className="col-12">
                            {listViewCard()}
                        </div>
                    </div>

                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination mt-4 mb-5">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>
                </>
            );
        } else if (!addProduct && listviewData?.length > 0 && cardViewList) {
            return (
                <>
                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination mt-4">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>

                    <div class="col-12 mt-4">
                        <ThumbNailView
                            cardViewData={listviewData}
                            goToProductDetails={(item) => goToDetails(item)}
                            myFun={(val) => myFun(val)}
                        />
                    </div>

                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination mt-4 mb-5">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>
                </>
            );
        } else {
            return;
        }
    };

    const expandDropdown = (index) => {
        if (expandIndex === index) {
            setExpandIndex("");
        } else {
            setExpandIndex(index);
        }
    };

    const handleFileChange = (e) => {
        console.log(e)
        setFormValue({ ...formValue, [e.target.name]: e.target.files[0] });
        let formfilevalue = e.target.value;
        setFilePath(e?.target?.files?.[0])
    };
    console.log(filePath)

    const submitUplaodData = async () => {
        let formData = new FormData()
        formData.append("file", filePath);
        await postMethodWithTokenProgressCSV(urls.uploadCSV, formData).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.response)
                setUploadModal(false)
                listedItem()
                getCurrentStatus()
            } else {
                toast.error(response?.data?.response)
            }
        })

    }

    const openUploadModal = () => {
        setUploadModal(true)
        // getCurrentStatus()
        // setUploadImage()
    }

    const handleCloneConfrimation = (value) => {
        getCurrentStatus()
        setCloneId("")
        setCloneId(value)
        setStructure(value?.structure)
        setCloneConfirmation(true)
        setCloneUpc("")
        setCloneSku("")
    }

    const ToDelete = (id) => {
        setConfirmation(true);
        getCurrentStatus()
        setIndexIdToDelete(id);
    };

    const changeToFloat = (value) => {
        let valueInFloat = value !== null ? value.toFixed(2) : "";
        return valueInFloat;
    };

    const listViewCard = () => {
        // if (!noMatch) {
        return (
            <>
                <div className="row listSectionAddproduct ft-14 mt-3">
                    <div className="col-1 "></div>
                    <div className="col-3 productItem f-600">Product Name</div>
                    <div className="col-1 productItem f-600">SKU</div>
                    <div className="col-1 productItem f-600">Qty.</div>
                    <div className="col-1 productItem f-600">Cost</div>
                    <div className="col-1 productItem f-600">Sale Price</div>
                    <div className="col-1 productItem f-600">List Price</div>
                    <div className="col-1 productItem f-600">Inv Alert</div>
                    <div className="col-1 productItem f-600">Status</div>
                    <div className="col-1 productItem f-600">Actions</div>
                </div>

                {!noMatch && listviewData.length > 0 ? listviewData.map((item, index) => {
                    return (
                        <>
                            <div key={index + 1} className="row listSectionAddproductdetail ft-14 mt-3 pt-3 pb-3" style={{ background: "#F6F6F6" }}>
                                <div className="col-1 d-flex productImg" style={{ alignItems: "center", cursor: "pointer" }}>
                                    <div className="d-flex">
                                        {item.productNewStatus !== true ?
                                            <Tooltip classes={{ tooltip: classes.draftToolTip, arrow: classes.draftToolTipArrow }} title="Draft" placement="left" arrow>
                                                <div className="prolisting-color" style={{ backgroundColor: "rgb(255, 127, 0)" }}></div>
                                            </Tooltip>
                                            : item.status === true ?
                                                <Tooltip classes={{ tooltip: classes.activeToolTip, arrow: classes.activeToolTipArrow }} title="Active" placement="left" arrow>
                                                    <div className="prolisting-color" style={{ backgroundColor: "rgb(0, 180, 0)" }}></div>
                                                </Tooltip>
                                                :
                                                <Tooltip classes={{ tooltip: classes.deactiveToolTip, arrow: classes.deactiveToolTipArrow }} title="Deactive" placement="left" arrow>
                                                    <div className="prolisting-color" style={{ backgroundColor: "rgb(255, 0, 0)" }}></div>
                                                </Tooltip>
                                        }
                                    </div>
                                    <div className="d-flex ml-2" style={{ alignItems: "center" }}>
                                        {item.structure === "parent" && expandIndex !== index ? (
                                            <i style={{ marginRight: "10px", fontSize: "20px", verticalAlign: "middle" }} className="fa fa-angle-down iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                        ) :
                                            item.structure === "parent" && expandIndex === index ? (
                                                <i style={{ marginRight: "10px", fontSize: "20px", verticalAlign: "middle" }} className="fa fa-angle-up iconColorArrow" onClick={() => expandDropdown(index)} aria-hidden="true"></i>
                                            )
                                                : null}

                                        {item.images && item.images.slice(0, 1).map((item, i) => {
                                            return (
                                                <img
                                                    onClick={() => goToDetails(item)}
                                                    src={item?.original}
                                                    alt=""
                                                    className="listedImg"
                                                />
                                            );
                                        })}

                                        {typeof json_data !== "undefined" && item.images.length == 0 &&
                                            <img src={NoImage} alt="" className="listedImg" />
                                        }
                                    </div>
                                </div>
                                <div className="col-3 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                    <div className="row">
                                        <div className="col-12">{item.product_name}</div>
                                        <div className="col-12">
                                            {expandIndex !== index && item?.children?.length > 0 && item.children.map((citem, cindex) => {
                                                return (
                                                    <>
                                                        <div className="row">
                                                            <div className="d-flex" style={{ textTransform: "capitalize" }}>
                                                                {citem.attributes.length > 0 && citem.attributes.map((aitem, aindex) => {
                                                                    return (
                                                                        <>
                                                                            {aitem.value_text !== 'color_code_swatch' &&
                                                                                <>
                                                                                    {aindex === 0 ?
                                                                                        <div className="d-flex" style={{ marginBottom: "5px", color: (citem?.color_code && citem?.image_picker?.length !== 0) || (citem.color_code === "#fffdfc" || citem.color_code === "#ffffff") || (citem?.color_code === null) ? "#203864" : "white", background: citem.color_code && citem?.image_picker?.length > 0 ? "" : citem.color_code, padding: "0px 7px 0 7px" }}><b>{aitem.name} :</b>
                                                                                            {citem?.image_picker?.length > 0 ? citem.image_picker.map((mitem, mindex) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <div>
                                                                                                            <img src={mitem?.original} alt="" className="pickerImage" />
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                                :
                                                                                                aitem?.value_text
                                                                                            }</div>
                                                                                        :
                                                                                        <div style={{ marginBottom: "5px", color: (citem?.color_code && citem?.image_picker?.length > 0) || (citem.color_code === "#fffdfc" || citem.color_code === "#ffffff") || (citem?.color_code === null) ? "#203864" : "white", background: citem?.color_code && citem?.image_picker?.length > 0 ? "" : citem.color_code, padding: "0px 5px 0 0px" }}><b>, {aitem?.name} :</b>  {aitem?.value_text}</div>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1 d-flex pds" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                    {item?.partner_sku}
                                </div>
                                <div className="col-1 d-flex pds" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                    {item?.num_in_stock}
                                </div>
                                <div className="col-1 d-flex pds" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                    {item?.invoice_cost}
                                </div>
                                <div className="col-1 d-flex pds" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                    {changeToFloat(item.price)}
                                </div>
                                <div className="col-1 d-flex pds" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                    {item?.compare_at_price && changeToFloat(parseInt(item?.compare_at_price))}
                                </div>
                                <div className="col-1 d-flex pds" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetails(item)}>
                                    {item?.low_stock_threshold}
                                </div>
                                <div className="col-1 d-flex pds" style={{ alignItems: "center" }}>
                                    <Switch
                                        checked={item?.status}
                                        className={(item.status) ? 'selected_sett' : 'unselected_sett'}
                                        onColor="#1D3762"
                                        offColor="#808080"
                                        onHandleColor="#F2F5F7"
                                        offHandleColor="#F2F5F7"
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        name="status"
                                        onChange={(event) => productStatusUpdate(event, item.id)}
                                    />
                                </div>
                                <div className="col-1 d-flex pds" style={{ alignItems: "center" }}>
                                    <div className="d-flex">
                                        {/* <MoreVertIcon onClick={() => handleCloneConfrimation(item)} style={{ cursor: "pointer", color: "#000000" }} /> */}
                                        <FileCopyIcon onClick={() => handleCloneConfrimation(item)} style={{ cursor: "pointer", color: "#000000" }} />
                                    </div>
                                    <div className="d-flex ml-2 set_left_padd_small">
                                        <EditIcon fontSize="small" onClick={() => goToDetails(item)} style={{ cursor: "pointer", color: "#000000" }} />
                                    </div>
                                    <div className="d-flex ml-2 set_left_padd_small">
                                        <DeleteIcon fontSize="small" onClick={() => ToDelete(item.id)} style={{ cursor: "pointer", color: "#eb2813" }} />
                                    </div>
                                </div>
                            </div>
                            <OnHoldModal isOnHold={modalMe} />

                            {expandIndex === index && listviewData[index].children.map((items, index) => {
                                return (
                                    <div key={index + 1} className="row ft-14 ml-1 mt-3 pt-2 pb-2" style={{ background: "#F6F6F6" }}>
                                        <div className="col-1 d-flex productImg" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                            <div className="d-flex">
                                                {items.status === true ?
                                                    <Tooltip classes={{ tooltip: classes.activeToolTip, arrow: classes.activeToolTipArrow }} title="Active" placement="left" arrow>
                                                        <div className="prolisting-color" style={{ backgroundColor: "rgb(0, 180, 0)" }}></div>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip classes={{ tooltip: classes.deactiveToolTip, arrow: classes.deactiveToolTipArrow }} title="Deactive" placement="left" arrow>
                                                        <div className="prolisting-color" style={{ backgroundColor: "rgb(255, 0, 0)" }}></div>
                                                    </Tooltip>
                                                }
                                            </div>
                                            <div className="d-flex ml-2">
                                                {items.images && items.images.slice(0, 1).map((items, i) => {
                                                    return (
                                                        <img className="listedImg" src={items?.original} alt="" />
                                                    );
                                                })}

                                                {typeof json_data !== "undefined" && items?.images?.length == 0 &&
                                                    <img className="listedImg" src={NoImage} alt="" />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-3 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                            <div className="row">
                                                <div className="col-12" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{items?.product_name}</div>
                                                <div className="row mt-2">
                                                    <div className="d-flex ml-4" style={{ textTransform: "capitalize" }}>
                                                        {items.attributes.length > 0 && items.attributes.map((aitem, aindex) => {
                                                            return (
                                                                <>
                                                                    {aitem.value_text !== 'color_code_swatch' &&
                                                                        <>
                                                                            {aindex === 0 ?
                                                                                <div className="d-flex" style={{ marginBottom: "15px", color: (items?.color_code && items?.image_picker?.length !== 0) || (items.color_code === "#fffdfc" || items.color_code === "#ffffff") || (items?.color_code === null) ? "#203864" : "white", background: items.color_code && items?.image_picker?.length > 0 ? "" : items?.color_code, padding: "0px 7px 0 7px" }}><b>{aitem.name} :</b>
                                                                                    {items?.image_picker?.length > 0 ? items.image_picker.map((mitem, mindex) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div>
                                                                                                    <img src={mitem?.original} alt="" className="pickerImage" />
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }) :
                                                                                        aitem?.value_text
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <div style={{ marginBottom: "15px", color: (items?.color_code && items?.image_picker?.length > 0) || (items.color_code === "#fffdfc" || items.color_code === "#ffffff") || (items?.color_code === null) ? "#203864" : "white", background: items?.color_code && items?.image_picker?.length > 0 ? "" : items.color_code, padding: "0px 5px 0 0px" }}><b>, {aitem?.name} :</b>  {aitem?.value_text}</div>
                                                                            }
                                                                        </>
                                                                    }

                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                            {items?.partner_sku}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                            {items?.num_in_stock}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                            {items?.invoice_cost}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                            {changeToFloat(items?.price)}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                            {items?.compare_at_price && changeToFloat(parseInt(items?.compare_at_price))}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => goToDetailsProduct(item, items, "child")}>
                                            {items?.low_stock_threshold}
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            <Switch
                                                checked={items.status}
                                                className={(items.status) ? 'selected_sett' : 'unselected_sett'}
                                                onColor="#1D3762"
                                                offColor="#808080"
                                                onHandleColor="#F2F5F7"
                                                offHandleColor="#F2F5F7"
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                name="status"
                                                onChange={(event) => productStatusUpdate(event, items.id)}
                                            />
                                        </div>
                                        <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                            <div className="d-flex">
                                                {/* <FileCopyIcon onClick={() => handleCloneConfrimation(items)} style={{ cursor: "pointer" }} /> */}
                                            </div>
                                            <div className="d-flex ml-2">
                                                <EditIcon onClick={() => goToDetailsProduct(item, items)} style={{ cursor: "pointer" }} />
                                            </div>
                                            {/* <div className="d-flex ml-2">
                                                <DeleteIcon onClick={() => ToDelete(items.id)} style={{ cursor: "pointer", color: "#eb2813" }} />
                                            </div> */}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    );
                })
                    :
                    <div className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#F6F6F6", textAlign: "center", alignItems: "center", height: "75px" }}>
                        <div className="col-12 " >No Product Found.</div>
                    </div>
                }
            </>
        );
    };

    const searchProduct = async (text) => {
        let storeId = getStoreId();
        if (text === "") {
            setNoMatch(false);
            listedItem();
        } else {
            await getMethod(urls.aboutStore + storeId + "/searchproduct/?product_name=" + text).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (response?.data?.results?.data?.length > 0) {
                        setNoMatch(false);
                        setCount(Math.ceil(response.data.count / itemsPerPage))
                        setPage(1)
                        setListviewData(response?.data?.results?.data);
                    } else {
                        setNoMatch(true);
                    }
                }
            });
        }
    };

    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);
        debounceCall(e.target.value);
    };

    const debounceCall = debounce(searchProduct, 500);


    const handleSaveData = () => {
        var objMap = new Map(Object.entries(childrenClone_upc));
        let array = [];
        objMap.forEach((item, key) => {
            array.push(item[0]);
        });
        setVaraintData(array);
    }

    const handleChangeUpc = (id, val) => {
        const { name, value } = val.target
        setChildrenColne_upc(prevState => ({
            ...prevState,
            [name]: [{ "product_id": +name, "clone_upc": value }]
        }))
        setTimeout(() => {
            handleSaveData();
        }, 2000);
        if (name === name) {
            postUPCListCheck(value)
        }

    }

    const handleChangeSku = (id, val) => {
        const { name, value } = val.target
        setChildrenColne_sku(prevState => ({
            ...prevState,
            [name]: [{ "product_id": +name, "clone_sku": value }]
        }))
        if (name === name) {
            postSKUListCheck(value)
        }
    }

    return (
        <>
            <div className={props?.props?.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"} style={{ marginTop: props.onBoardingStatus === false ? "-45px" : "" }}>
                {!addProduct && hide &&
                    <>
                        <div className="row ml-3 mr-3 mobileResponsive">
                            <div className="col-12 heading-title" style={{ marginTop: props.onBoardingStatus === false ? "-3px" : "15px" }}> Store Listings</div>
                        </div>
                        <div className="row ml-3 mr-3 mobileResponsive">
                            <div className="col-sm-12 col-lg-8" style={{ marginTop: props.onBoardingStatus === false ? "10px" : "13px" }} >
                                <div className="muiDescripationInfo">
                                    Stock your online store with as many products as you can. Let your perspective customers discover what you have in store.
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 d-flex justify-content-end" style={{ top: props.onBoardingStatus === false ? "45px" : "-20px" }}>
                                                <Button onClick={() => productPage()} className="btn btn-cancle" name="Add Product" />
                                                {/* </div>
                                        <div className="col-lg-4 col-md-4 d-flex justify-content-end btn-mobile-margin-none" style={{ top: "-25px" }}> */}
                                                <Button onClick={() => openUploadModal()} className="btn btn-cancle ml-3" name="Batch upload">
                                                    <img src={importIcon} />
                                                </Button>
                                                {/* </div>
                                        <div className="col-lg-4 col-md-4 d-flex justify-content-end btn-mobile-margin-none" style={{ top: "-25px" }}> */}
                                                {/* <a href={CSVFile} download style={{ webkitUserDrag: "none" }}>
                                                <Button className="btn btn-cancle" name="Template XLSX">
                                                    <img src={importIcon} />
                                                </Button>
                                            </a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {props.onBoardingStatus === false && listviewData.length === 0 ?
                            <div className="d-flex justify-content-center"><img src={emptyStore} alt="" /></div>
                            :
                            props.onBoardingStatus !== false && listviewData.length === 0 &&
                            <div className="d-flex justify-content-center"><img src={emptyStore} alt="" /></div>
                        }
                    </>
                }

                {!addProduct && hide && listviewData.length > 0 &&
                    <div className="row ml-3 mr-3 mt-3 mobileResponsive" style={{ paddingTop: props.onBoardingStatus === false ? "20px" : "" }}>
                        <div className="col-12 mb-2">
                            <div className="h4-label">Listed Item</div>
                        </div>
                        <div className="col-xl-4 col-lg-5 col-md-6">
                            <TextField
                                name="search"
                                label="Search Products"
                                variant="outlined"
                                value={searchInput}
                                onChange={(event) => handleSearchInputChange(event)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-6">
                            <div className="d-flex justify-content-end">
                                <img
                                    onClick={() => listViewData()}
                                    className="paddingRight08"
                                    src={ListViewOption}
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                />
                                <img
                                    className="paddingRight0"
                                    onClick={() => cardView()}
                                    src={GridIcon}
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    </div>
                }

                <div className="row ml-3 mr-3 mobileResponsive">
                    {addProduct &&
                        <TabBar backHandle={() => productPage()} generalDetails={listviewData} />
                    }

                    <div className="col-12">
                        {loading && addProduct === false ? (
                            <SpinnerLoader />
                        ) :
                            // loading === false && addProduct === false && listviewData.length === 0 ? (
                            //     <StoreListing />
                            // )
                            (
                                ""
                            )}
                    </div>
                </div>

                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    {showProductList()}
                    <OnHoldModal isOnHold={modalMe} />
                </div>

                {onBoarding === false && addProduct === false &&
                    <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                        <div className="col-12 d-flex justify-content-end">
                            <Button onClick={() => history.push("/financials")} name={listviewData.length > 0 ? "Next" : "Skip"} className="btn btn-primary mb-5" />
                        </div>
                    </div>
                }

                <div className="row  ml-3 mr-3 mt-3">
                    <div className="col-12">
                        <Modal isOpen={uploadModal} style={allStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                            <div className="mt-2 mb-4">
                                <div className="row">
                                    <div className="h2-label mb-3 mt-2 col-12 d-flex justify-content-center">Batch upload</div>
                                </div>
                                <div className=" col-12 pt-3">
                                    <div className={`input-group customerFileBtn`}>
                                        <label className="input-group-text" for="inputGroupFile">Upload XLSX</label>
                                        <input type="file" accept={".xlsx"} className="form-control" id="inputGroupFile" onChange={(e) => handleFileChange(e)} />
                                    </div>
                                </div>

                                {/* <div className=" col-12 "> */}
                                {/* row mt-1 container */}
                                {/* <div className="">
                                        <div class="csv-file-upload-wrapper d-flex justify-content-center input-group  customerFileBtn" data-text={filePath}>
                                            <label className="input-group-text left_btn_text" htmlFor="inputGroupFile">upload XLSX</label>        
                                            <input name="file" type="file" id={"csvFileInput"} accept={".xlsx"} class="csv-file-upload-field form-control" placeholder="upload XLSX" onChange={(e) => handleFileChange(e)} />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-12 mt-4 d-flex justify-content-center" style={{ paddingLeft: "55px" }}>
                                    <Button name="Cancel" onClick={() => setUploadModal(false)} className="btn btn-sm btn-cancle mr-3" />
                                    <Button name="save" onClick={() => submitUplaodData()} className="btn btn-sm btn-primary" />
                                </div>

                                <div className="col-12 mt-3 mb-4 d-flex justify-content-center" style={{ paddingLeft: "35px" }}>
                                    <a href={CSVFile} download style={{ webkitUserDrag: "none", fontSize: "16px", fontWeight: 600 }}>Download Template</a>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>

                <div className="row ml-3 mr-3 mt-3">
                    <div className="col-12">
                        <Modal isOpen={cloneConfirmation} style={cloneStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                            <OnHoldModal isOnHold={modalMe} />
                            <form onSubmit={(e) => cloneId.children && cloneId.children.length === 0 ? cloneProduct(e, cloneId.id) : cloneProductParent(e, cloneId.id)}>
                                <div className="mt-2 mb-4">
                                    <div className="row">
                                        <div className="col-12 text-center" style={{ fontSize: "16px", fontWeight: 700 }}>Are you sure you want to clone this product?</div>
                                        {cloneId.children && cloneId.children.length === 0 ?
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-2" />
                                                    <div className="col-8 mt-3">
                                                        <TextField
                                                            label="UPC"
                                                            name="UPC"
                                                            variant="outlined"
                                                            value={cloneUpc}
                                                            onChange={(e) => { handleInputUPCChange(e) }}
                                                            required
                                                        />
                                                        <div className="mt-3">
                                                            <TextField
                                                                label="SKU"
                                                                name="SKU"
                                                                variant="outlined"
                                                                value={cloneSku}
                                                                onChange={(e) => { handleInputSKUChange(e) }}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-2" />
                                                </div>
                                            </div>
                                            :
                                            cloneId.children && cloneId.children.length > 0 && cloneId.children.map((k, i) => {
                                                return (
                                                    <>
                                                        <div className="col-12 mt-3 pl-5 pr-5 d-flex justify-content-start" style={{ textTransform: "capitalize" }}>
                                                            {k.attributes && k.attributes.length > 0 && k.attributes.map((j, l) => {
                                                                if (j.value_text !== "color_code_swatch") {
                                                                    return (
                                                                        <>
                                                                            {l === 0 ?
                                                                                <div className="d-flex" style={{ marginBottom: "15px", color: (k?.color_code && k?.image_picker?.length !== 0) || (k?.color_code === "#fffdfc" || k?.color_code === "#ffffff") || (k?.color_code === null) ? "#203864" : "white", background: k?.color_code && k?.image_picker?.length > 0 ? "" : k?.color_code, padding: "0px 7px 0 7px" }}><b>{j.name} :</b>
                                                                                    {k?.image_picker?.length > 0 ? k.image_picker.map((mitem, mindex) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div>
                                                                                                    <img src={mitem?.original} alt="" className="pickerImage" />
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                        :
                                                                                        j.value_text
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <div style={{ marginBottom: "15px", color: (k?.color_code && k?.image_picker?.length > 0) || (k?.color_code === "#fffdfc" || k?.color_code === "#ffffff") || (k?.color_code === null) ? "#203864" : "white", background: k?.color_code && k?.image_picker?.length > 0 ? "" : k?.color_code, padding: "0px 5px 0 0px" }}><b>, {j?.name} : </b>  {j?.value_text}</div>
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            })}
                                                        </div>

                                                        <div className="col-12 m-0 pl-5 pr-5 d-flex justify-content-center">
                                                            <TextField
                                                                required
                                                                label="UPC"
                                                                name={k.id}
                                                                variant="outlined"
                                                                // value={cloneUpc}
                                                                onChange={(e) => { handleChangeUpc(k.id, e) }}
                                                                style={{ width: "100%", marginRight: "5px", padding: 0 }}

                                                            />

                                                            <TextField
                                                                required
                                                                label="SKU"
                                                                name={k.id}
                                                                variant="outlined"
                                                                // value={cloneSku}
                                                                onChange={(e) => { handleChangeSku(k.id, e) }}
                                                                style={{ width: "100%", marginLeft: "5px", padding: 0 }}
                                                            />
                                                        </div>
                                                    </>
                                                )

                                            })
                                        }
                                        <div className="col-12 mt-4  d-flex justify-content-center">
                                            <p onClick={() => { setCloneConfirmation(false); getCurrentStatus() }} name="Cancel" className="btn btn-sm btn-cancle mr-3" >Cancel</p>
                                            <Button disabled={buttonDisabled} type="onSubmit" name="Clone" className="btn btn-sm btn-primary" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Modal>
                    </div>
                </div>

                <div className="row ml-3 mr-3 mt-3">
                    <div className="col-12">
                        <Modal isOpen={confirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                            <div className="mt-4 mb-4">
                                <div className="row">
                                    <OnHoldModal isOnHold={modalMe} />
                                    <div className="col-12 text-center">Are you sure you want to delete this product?</div>
                                    <div className="col-12 mt-4  d-flex justify-content-center">
                                        <Button onClick={() => { setConfirmation(false); getCurrentStatus() }} name="Cancel" className="btn btn-sm btn-cancle mr-3" />
                                        <Button onClick={() => { deleteProduct(indexIdToDelete); }} name="Delete" className="btn btn-sm btn-primary" />
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AddProduct;

